<mdb-progress-bar class="progress" mode="indeterminate" *ngIf="showProgressBar"></mdb-progress-bar>

<div mdbModal #onlineOfflineModal="mdbModal" [config]="{ignoreBackdropClick: true, keyboard: false}" class="modal fade top" tabindex="-1" role="dialog"
    aria-labelledby="onlineOfflineModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-frame modal-top p-0" role="document">
        <div class="modal-content">
            <div class="modal-body app-online" *ngIf="isAppOnline == true">
                <h4 class="mb-0 my-auto">
                    <mdb-icon fas icon="wifi"></mdb-icon>
                    <span class="ml-2">
                        Heyy! You are back online
                    </span>
                </h4>
            </div>
            <div class="modal-body app-offline" *ngIf="isAppOnline == false">
                <h4 class="mb-0 my-auto">
                    <mdb-icon fas icon="exclamation-triangle"></mdb-icon>
                    <span class="ml-2">
                        Uh oh! You are offline, please check your connection
                    </span>
                </h4>
            </div>
        </div>
    </div>
</div>

<router-outlet (activate)="onActivate($event)"></router-outlet>