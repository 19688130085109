import { Injectable, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { finalize } from "rxjs/operators";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  UserCredential,
} from "firebase/auth";

@Injectable()
export class FirebaseAuthServices {
  constructor(public afAuth: AngularFireAuth) {}

  registerUser(email, password) {
    return new Promise<any>((resolve, reject) => {
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, email, password).then(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });

    // return new Promise<any>((resolve, reject) => {
    //   createUserWithEmailAndPassword(email, password).then(
    //     (res) => resolve(res),
    //     (err) => reject(err)
    //   );
    // });
  }

  getIdToken(): Promise<string> {
    return new Promise((resolve) => {
      // this.afAuth.onAuthStateChanged((user) => {
      //   if (user) {
      //     user.getIdToken().then((idToken) => {
      //       try {
      //         return resolve(idToken);
      //       } catch (err) {
      //         console.log(err);
      //       }
      //     });
      //   } else {
      //     return resolve(null);
      //   }
      // });
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          user.getIdToken().then((idToken) => {
            try {
              return resolve(idToken);
            } catch (err) {
              console.log(err);
            }
          });
        } else {
          return resolve(null);
        }
      });
    });
  }

  getUserFirebaseProfile() {
    return new Promise<any>(async (resolve) => {
      this.afAuth.onAuthStateChanged(function (user) {
        return resolve(user);
      });
    });
  }

  updateUserProfile(fullName) {
    let currentUser = this.afAuth.currentUser;
    return new Promise<any>(async (resolve, reject) => {
      (await currentUser).updateProfile({ displayName: fullName }).then(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  signInUser(email, password) {
    // return new Promise<any>((resolve, reject) => {
    //   this.afAuth.signInWithEmailAndPassword(email, password).then(
    //     (res) => resolve(res),
    //     (err) => reject(err)
    //   );
    // });

    return new Promise<any>((resolve, reject) => {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, email, password).then(
        (res: UserCredential) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  passwordReset(email) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.sendPasswordResetEmail(email).then(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  signOutUser() {
    return new Promise((resolve, reject) => {
      if (this.afAuth.currentUser) {
        this.afAuth.signOut().then(
          (res) => resolve(res),
          (err) => reject(err)
        );
      }
    });
  }

  resendVerificationEmailsFromFirebase() {
    return new Promise<any>(async (resolve, reject) => {
      if (this.afAuth.currentUser) {
        (await this.afAuth.currentUser).sendEmailVerification().then(
          (res) => resolve(res),
          (err) => reject(err)
        );
      }
    });
  }

  userDetails() {
    return this.afAuth.currentUser;
  }
}

export class FirebaseFileUploadAdapter {
  loader: any;

  constructor(
    loader,
    private callbackFunc: (n: number) => any,
    private firebaseLocationPath,
    private firebaseStorageRef: AngularFireStorage
  ) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve) => {
          var n = Date.now();
          this.firebaseLocationPath = this.firebaseLocationPath || "other";
          const filePath = `${this.firebaseLocationPath}/${n}`;

          if (this.firebaseStorageRef) {
            let storage = this.firebaseStorageRef.ref(filePath);
            let uploadTask = this.firebaseStorageRef.upload(filePath, file);

            uploadTask.percentageChanges().subscribe((percentage) => {
              this.callbackFunc(percentage);
            });

            uploadTask
              .snapshotChanges()
              .pipe(
                finalize(() => {
                  storage.getDownloadURL().subscribe((url) => {
                    if (url) {
                      resolve({
                        default: url,
                      });
                    }
                  });
                })
              )
              .subscribe();
          }
        })
    );
  }
}
