import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { tap, map, switchMap, first } from "rxjs/operators";
import { of } from "rxjs";
import { StorageHandler } from "src/app/helpers/storage-handler";
import { firebaseRealTimeDBUrls } from "src/config";

@Injectable({
  providedIn: "root",
})
export class PresenceService {
  //-- 0 - offline, 1 - online, 2 - away
  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFireDatabase,
    private storageHandler: StorageHandler
  ) {
    this.updateOnUser().subscribe();
    this.updateOnDisconnect().subscribe();
    this.updateOnAway();
  }

  getPresence(uid: number) {
    return this.db
      .object(`${firebaseRealTimeDBUrls.users}/${uid}/status`)
      .valueChanges();
  }

  getUser() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  async setPresence(status: string) {
    const userId = this.storageHandler.getUserId();
    if (userId) {
      return this.db
        .object(`${firebaseRealTimeDBUrls.users}/${userId}/status`)
        .set(status);
    }
  }

  organizationUserIdChangePresence(uid: number) {
    return this.db
      .object(
        `${firebaseRealTimeDBUrls.users}/${uid}/currentOrganizationUserId`
      )
      .valueChanges();
  }

  // Updates status when logged-in connection to Firebase starts
  updateOnUser() {
    const connection = this.db
      .object(".info/connected")
      .valueChanges()
      .pipe(map((connected) => (connected ? "1" : "0")));

    return this.afAuth.authState.pipe(
      switchMap((user) => (user ? connection : of("0"))),
      tap((status) => this.setPresence(status))
    );
  }

  updateOnDisconnect() {
    return this.afAuth.authState.pipe(
      tap((user) => {
        const userId = this.storageHandler.getUserId();
        if (userId) {
          this.db
            .object(`${firebaseRealTimeDBUrls.users}/${userId}/status`)
            .query.ref.onDisconnect()
            .set("offline");
        }
      })
    );
  }

  // User navigates to a new tab, case 3
  updateOnAway() {
    document.onvisibilitychange = (e) => {
      document.visibilityState === "hidden"
        ? this.setPresence("2")
        : this.setPresence("1");
    };
  }
}
