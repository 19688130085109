import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FirebaseAuthServices } from './firebase-auth.services';
import { StorageHandler } from './storage-handler';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private firebaseAuthServices: FirebaseAuthServices, private storageHandler: StorageHandler) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.firebaseAuthServices.getIdToken()).pipe(
      switchMap((token) => {
        let headers = req.headers;
        if (token) {
          headers = req.headers.set('Authorization', 'Bearer ' + token);
        }

        // let manipulatedBody = req.body;
        // if (req.method == 'POST') {
        //   manipulatedBody = this.storageHandler.encryptUsingAES256(JSON.stringify(req.body));
        // }

        const requestClone = req.clone({
          headers: headers,
          // body: manipulatedBody,
          // responseType: 'text',
        });
        return next.handle(requestClone);
      })
    );
  }
}
