import { NgModule, Injector } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { APP_BASE_HREF, CommonModule } from "@angular/common";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
// import { DeviceDetectorModule } from 'ngx-device-detector';

import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { MDBSpinningPreloader } from "ng-uikit-pro-standard";
import { MdbTimePickerModule } from "ng-uikit-pro-standard";
import { ToastModule } from "ng-uikit-pro-standard";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { EventEmitterService } from "./shared/forms/services/event-emitter.service";

import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { firebaseConfig, config } from "src/config";
import { CommunicationsService } from "./modules/common/services/communications.service";
import { PresenceService } from "./modules/common/services/presence.service";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { RequestInterceptor } from "./helpers/request.interceptor";
import { FirebaseAuthServices } from "./helpers/firebase-auth.services";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireMessagingModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    MDBBootstrapModulesPro.forRoot(),
    FormsModule,
    MdbTimePickerModule,
    ToastModule.forRoot(),
    ReactiveFormsModule,
    // DeviceDetectorModule,
  ],
  providers: [
    MDBSpinningPreloader,
    EventEmitterService,
    CommunicationsService,
    PresenceService,
    FirebaseAuthServices,
    {
      provide: APP_BASE_HREF,
      useValue: "/",
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  static injector: Injector;
  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
