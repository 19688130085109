export enum NotificationType {
  Permission = 1,
  Push = 2,
}

export enum NotificationTemplates {
  Permission = '<div class="font-weight-bold">Enable notifications?</div><div class="mt-2 font-weight-light">{PermissionText}<div>',
  PushNotificationWithoutImage = '<a href="{NotificationRedirect}"><div class="d-flex"><div class="pr-3"> <img class="notification-icon" src="{PushIconURL}" imageType="user" /></div><div><div class="font-weight-bold">{PushTitle}</div><div class="font-weight-light mt-1 small">{PushBody}</div></div></div> </a>',
  PushNotificationWithImage = '<a href="{NotificationRedirect}"><div class="d-flex"><div class="pr-3"><img class="notification-icon" src="{PushIconURL}" /></div><div><div class="font-weight-bold">{PushTitle}</div><div class="font-weight-light mt-1 small">{PushBody}</div></div></div><div class="mt-2"><img src="{PushImageURL}" class="notification-image" /></div></a>',
}
