export const config: any = {
  pointing: "prod", // sklocal || praveenaLocal || skLocalDev || praveenaLocalDev || skLocalProd || dev || prod
  defaultOrganizationURL: "gg", // es || gg || sg
  defaultBrandName: "Go Gamers Tech", // EStars || Go Gamers Tech || Shams Gaming
  hideMastercardPromotionDialog: false,
  rootURLs: {
    sklocal: "https://localhost:44395/",
    praveenaLocal: "https://localhost:44395/",
    skLocalDev: "https://localhost:44395/",
    praveenaLocalDev: "https://localhost:44395/",
    praveenaLocalProd: "https://localhost:44395/",
    skLocalProd: "https://localhost:44395/",
    dev: "https://gogamers-tech-dev.azurewebsites.net/",
    prod: "https://platform.gogamers.tech/", // https://estars.tech/ || https://platform.gogamers.tech/ || https://shamsgaming.com/
  },
  rootAPIURLs: {
    sklocal: "https://localhost:44395/api",
    praveenaLocal: "https://localhost:44375/api",
    skLocalDev: "https://localhost:44395/api",
    praveenaLocalDev: "https://localhost:44375/api",
    praveenaLocalProd: "https://localhost:44375/api",
    skLocalProd: "https://localhost:44395/api",
    dev: "https://gogamers-tech-dev.azurewebsites.net/api",
    prod: "https://platform.gogamers.tech/api", // https://platform.gogamers.tech/
  },
  stripe: {
    publishableKey: {
      sklocal:
        "pk_test_51KlsA4JeGQ7SEshZ7lQP4ONRY29qMuBvtGnLLZ9PYowiDBwKWMab537S8IJ1GI85LSUXc2ArDtBYBA6kHGNAfHxA009vOMAFLL",
      praveenaLocal:
        "pk_test_51KlsA4JeGQ7SEshZ7lQP4ONRY29qMuBvtGnLLZ9PYowiDBwKWMab537S8IJ1GI85LSUXc2ArDtBYBA6kHGNAfHxA009vOMAFLL",
      skLocalDev:
        "pk_test_51KlsA4JeGQ7SEshZ7lQP4ONRY29qMuBvtGnLLZ9PYowiDBwKWMab537S8IJ1GI85LSUXc2ArDtBYBA6kHGNAfHxA009vOMAFLL",
      praveenaLocalDev:
        "pk_test_51KlsA4JeGQ7SEshZ7lQP4ONRY29qMuBvtGnLLZ9PYowiDBwKWMab537S8IJ1GI85LSUXc2ArDtBYBA6kHGNAfHxA009vOMAFLL",
      praveenaLocalProd:
        "pk_test_51KlsA4JeGQ7SEshZ7lQP4ONRY29qMuBvtGnLLZ9PYowiDBwKWMab537S8IJ1GI85LSUXc2ArDtBYBA6kHGNAfHxA009vOMAFLL",
      skLocalProd:
        "pk_test_51KlsA4JeGQ7SEshZ7lQP4ONRY29qMuBvtGnLLZ9PYowiDBwKWMab537S8IJ1GI85LSUXc2ArDtBYBA6kHGNAfHxA009vOMAFLL",
      dev: "pk_test_51KlsA4JeGQ7SEshZ7lQP4ONRY29qMuBvtGnLLZ9PYowiDBwKWMab537S8IJ1GI85LSUXc2ArDtBYBA6kHGNAfHxA009vOMAFLL",
      prod: "pk_live_51KlsA4JeGQ7SEshZPuLZxN79ne0588miMozadjli7mjEasOKQ7fkA6715YT1lQyy9ULrQzRX4m5yq47H0KYoya3w00EZPdPnyf",
    },
  },
  firebase_content: {
    sklocal: {
      apiKey: "AIzaSyB58sS41-UglwndDcdiuklH11CyING6-Ik",
      authDomain: "go-gamers-tech-cea05.firebaseapp.com",
      databaseURL: "https://go-gamers-tech-cea05.firebaseio.com",
      projectId: "go-gamers-tech-cea05",
      storageBucket: "go-gamers-tech-cea05.appspot.com",
      messagingSenderId: "844433266576",
      appId: "1:844433266576:web:f662caf895870fa6abc515",
      measurementId: "G-3D6Q6Y1ZGD",
      googleMapKey: "AIzaSyCSgnwvTABMT2kEtEas0TVZ-4iS1cp9hQY",
      publicVapidKey:
        "BALibg9I4NDHJgrst4fvoWevBQBHChndYDRZs5jFfOf4fS1FcMlz6h3l1UncjBtJCbBw74r9hv17XfWQehgmcSE",
      firebaseRealTimeDBUrls: {
        messages: "messages-dev",
        users: "users-dev",
        likes: "likes",
        comments: "comments",
        updates: "updates",
        refreshRequest: "refreshRequest",
        mastercardBINNumber: "mastercardBINNumber",
      },
    },
    praveenaLocal: {
      apiKey: "AIzaSyB58sS41-UglwndDcdiuklH11CyING6-Ik",
      authDomain: "go-gamers-tech-cea05.firebaseapp.com",
      databaseURL: "https://go-gamers-tech-cea05.firebaseio.com",
      projectId: "go-gamers-tech-cea05",
      storageBucket: "go-gamers-tech-cea05.appspot.com",
      messagingSenderId: "844433266576",
      appId: "1:844433266576:web:f662caf895870fa6abc515",
      measurementId: "G-3D6Q6Y1ZGD",
      googleMapKey: "AIzaSyCSgnwvTABMT2kEtEas0TVZ-4iS1cp9hQY",
      publicVapidKey:
        "BALibg9I4NDHJgrst4fvoWevBQBHChndYDRZs5jFfOf4fS1FcMlz6h3l1UncjBtJCbBw74r9hv17XfWQehgmcSE",
      firebaseRealTimeDBUrls: {
        messages: "messages-dev",
        users: "users-dev",
        likes: "likes",
        comments: "comments",
        updates: "updates",
        refreshRequest: "refreshRequest",
        mastercardBINNumber: "mastercardBINNumber",
      },
    },
    skLocalDev: {
      apiKey: "AIzaSyBkg818dO-7AeiVqDFIqcyF2FSrBV4-vlE",
      authDomain: "go-gamers-tech.firebaseapp.com",
      databaseURL: "https://go-gamers-tech.firebaseio.com",
      projectId: "go-gamers-tech",
      storageBucket: "go-gamers-tech.appspot.com",
      messagingSenderId: "277964915379",
      appId: "1:277964915379:web:52898143d0249a91ba66c6",
      measurementId: "G-4NM0MR2ETQ",
      publicVapidKey:
        "BCXmPFl7XXg-x5LWdO_uLepv5OuDqtF3iJaV_g5HO2iWQw2d6KS-phoq3i_DoM4lPpSNyoLuPd7rcNfZJgLWS-s",
      googleMapKey: "AIzaSyCSgnwvTABMT2kEtEas0TVZ-4iS1cp9hQY",
      firebaseRealTimeDBUrls: {
        messages: "messages-dev",
        users: "users-dev",
        likes: "likes",
        comments: "comments",
        updates: "updates",
        refreshRequest: "refreshRequest",
        mastercardBINNumber: "mastercardBINNumber",
      },
    },
    praveenaLocalDev: {
      apiKey: "AIzaSyBkg818dO-7AeiVqDFIqcyF2FSrBV4-vlE",
      authDomain: "go-gamers-tech.firebaseapp.com",
      databaseURL: "https://go-gamers-tech.firebaseio.com",
      projectId: "go-gamers-tech",
      storageBucket: "go-gamers-tech.appspot.com",
      messagingSenderId: "277964915379",
      appId: "1:277964915379:web:52898143d0249a91ba66c6",
      measurementId: "G-4NM0MR2ETQ",
      publicVapidKey:
        "BCXmPFl7XXg-x5LWdO_uLepv5OuDqtF3iJaV_g5HO2iWQw2d6KS-phoq3i_DoM4lPpSNyoLuPd7rcNfZJgLWS-s",
      googleMapKey: "AIzaSyCSgnwvTABMT2kEtEas0TVZ-4iS1cp9hQY",
      firebaseRealTimeDBUrls: {
        messages: "messages-dev",
        users: "users-dev",
        likes: "likes",
        comments: "comments",
        updates: "updates",
        refreshRequest: "refreshRequest",
        mastercardBINNumber: "mastercardBINNumber",
      },
    },
    praveenaLocalProd: {
      apiKey: "AIzaSyBkg818dO-7AeiVqDFIqcyF2FSrBV4-vlE",
      authDomain: "go-gamers-tech.firebaseapp.com",
      databaseURL: "https://go-gamers-tech.firebaseio.com",
      projectId: "go-gamers-tech",
      storageBucket: "go-gamers-tech.appspot.com",
      messagingSenderId: "277964915379",
      appId: "1:277964915379:web:52898143d0249a91ba66c6",
      measurementId: "G-4NM0MR2ETQ",
      googleMapKey: "AIzaSyCSgnwvTABMT2kEtEas0TVZ-4iS1cp9hQY",
      publicVapidKey:
        "BCXmPFl7XXg-x5LWdO_uLepv5OuDqtF3iJaV_g5HO2iWQw2d6KS-phoq3i_DoM4lPpSNyoLuPd7rcNfZJgLWS-s",
      firebaseRealTimeDBUrls: {
        messages: "messages",
        users: "users",
        likes: "likes",
        comments: "comments",
        updates: "updates",
        refreshRequest: "refreshRequest",
        mastercardBINNumber: "mastercardBINNumber",
      },
    },
    dev: {
      apiKey: "AIzaSyBkg818dO-7AeiVqDFIqcyF2FSrBV4-vlE",
      authDomain: "go-gamers-tech.firebaseapp.com",
      databaseURL: "https://go-gamers-tech.firebaseio.com",
      projectId: "go-gamers-tech",
      storageBucket: "go-gamers-tech.appspot.com",
      messagingSenderId: "277964915379",
      appId: "1:277964915379:web:52898143d0249a91ba66c6",
      measurementId: "G-4NM0MR2ETQ",
      googleMapKey: "AIzaSyCSgnwvTABMT2kEtEas0TVZ-4iS1cp9hQY",
      publicVapidKey:
        "BCXmPFl7XXg-x5LWdO_uLepv5OuDqtF3iJaV_g5HO2iWQw2d6KS-phoq3i_DoM4lPpSNyoLuPd7rcNfZJgLWS-s",
      firebaseRealTimeDBUrls: {
        messages: "messages-dev",
        users: "users-dev",
        likes: "likes",
        comments: "comments",
        updates: "updates",
        refreshRequest: "refreshRequest",
        mastercardBINNumber: "mastercardBINNumber",
      },
    },
    skLocalProd: {
      apiKey: "AIzaSyBkg818dO-7AeiVqDFIqcyF2FSrBV4-vlE",
      authDomain: "go-gamers-tech.firebaseapp.com",
      databaseURL: "https://go-gamers-tech.firebaseio.com",
      projectId: "go-gamers-tech",
      storageBucket: "go-gamers-tech.appspot.com",
      messagingSenderId: "277964915379",
      appId: "1:277964915379:web:52898143d0249a91ba66c6",
      measurementId: "G-4NM0MR2ETQ",
      googleMapKey: "AIzaSyCSgnwvTABMT2kEtEas0TVZ-4iS1cp9hQY",
      publicVapidKey:
        "BCXmPFl7XXg-x5LWdO_uLepv5OuDqtF3iJaV_g5HO2iWQw2d6KS-phoq3i_DoM4lPpSNyoLuPd7rcNfZJgLWS-s",
      firebaseRealTimeDBUrls: {
        messages: "messages",
        users: "users",
        likes: "likes",
        comments: "comments",
        updates: "updates",
        refreshRequest: "refreshRequest",
        mastercardBINNumber: "mastercardBINNumber",
      },
    },
    prod: {
      apiKey: "AIzaSyBkg818dO-7AeiVqDFIqcyF2FSrBV4-vlE",
      authDomain: "go-gamers-tech.firebaseapp.com",
      databaseURL: "https://go-gamers-tech.firebaseio.com",
      projectId: "go-gamers-tech",
      storageBucket: "go-gamers-tech.appspot.com",
      messagingSenderId: "277964915379",
      appId: "1:277964915379:web:52898143d0249a91ba66c6",
      measurementId: "G-4NM0MR2ETQ",
      googleMapKey: "AIzaSyCSgnwvTABMT2kEtEas0TVZ-4iS1cp9hQY",
      publicVapidKey:
        "BCXmPFl7XXg-x5LWdO_uLepv5OuDqtF3iJaV_g5HO2iWQw2d6KS-phoq3i_DoM4lPpSNyoLuPd7rcNfZJgLWS-s",
      firebaseRealTimeDBUrls: {
        messages: "messages",
        users: "users",
        likes: "likes",
        comments: "comments",
        updates: "updates",
        refreshRequest: "refreshRequest",
        mastercardBINNumber: "mastercardBINNumber",
      },
    },
  },
  appId: "62655ef2-79eb-46b2-ad1f-0e225f4a1855",
  appIV: "4512631236589784",
  appKey: "4512631236746934",
  appPassword: "byr/PsCyNZbcpPC6Rt+WfZUDrUYUvcvzeX+VD7lUnrM=",
  defaultColoredBrandIconDarkURL:
    "https://firebasestorage.googleapis.com/v0/b/go-gamers-tech.appspot.com/o/Icons%2Fgg-tech-global-winner-logo-update.png?alt=media&token=4f53832b-39f4-46fa-a726-6580159f2add",
  defaultWhiteBrandIconURL:
    "https://firebasestorage.googleapis.com/v0/b/go-gamers-tech.appspot.com/o/Icons%2Fgg-tech-global-winner-logo-update.png?alt=media&token=4f53832b-39f4-46fa-a726-6580159f2add",
  // defaultColoredBrandIconDarkURL: 'https://firebasestorage.googleapis.com/v0/b/go-gamers-tech.appspot.com/o/Icons%2Fgo-gamers-tech-color-3.png?alt=media&token=f91a02b6-aba7-4a2c-84a5-f72bfe87e257',
  // defaultWhiteBrandIconURL: 'https://firebasestorage.googleapis.com/v0/b/go-gamers-tech.appspot.com/o/Icons%2Fgo-gamers-tech-white-1.png?alt=media&token=d29193ba-ed5b-40d4-9138-bfdd2dbb53a0',
};

export const rootAPIURL = config.rootAPIURLs[config.pointing];
export const rootURL = config.rootURLs[config.pointing];
export const firebaseConfig = config.firebase_content[config.pointing];
export const firebaseRealTimeDBUrls =
  config.firebase_content[config.pointing].firebaseRealTimeDBUrls;
export const stripePublishableKey =
  config.stripe.publishableKey[config.pointing];
