export enum MediaTypeEnum {
  ImageJPEG = 1,
  Tournaments = 2,
  ImagePNG = 3,
  VideoMP4 = 4,
  ImageGIF = 5,
  ImageJPG = 6,
  ImageALL = 7,
  ImageWebp = 8,
}

export enum MediaTypeNameEnum {
  ImageJPEG = 'image/jpeg',
  Tournaments = 'Tournaments',
  ImagePNG = 'image/png',
  VideoMP4 = 'video/mp4',
  ImageGIF = 'image/gif',
  ImageJPG = 'image/jpg',
  ImageALL = 'image/*',
  ImageWebp = 'image/webp',
  TextCTE = 'text/rte',
  VideoQuickTime = 'video/quicktime',
  ApplicationOctet = 'application/octet-stream',
  VideoXM4u = 'video/x-m4v',
}

export enum MediaCategoryEnum {
  Image = 'image/',
  Video = 'video/',
  TextCTE = 'text/rte',
}
