import { Injectable } from "@angular/core";
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { UserModel } from "./model/user.model";
import { UserRoleModel } from "./model/user-role.model";
import * as CryptoJS from "crypto-js";
import { config } from "src/config";
import { ThemeModel } from "./model/theme.model";
import { OrganizationModel } from "./model/organization.model";
import { NavigationMenuModel } from "./model/navigation.model";
import { CompleteUserDetail } from "./model/complete-user-detail.model";
import { UserRoleType } from "./enums/user-role.enums";

@Injectable({ providedIn: "root" })
export class StorageHandler {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  isUserAuthenticated(): boolean {
    const userData = this.getSignedInUserData();
    return userData && userData.UserId > 0;
  }

  getUserRoleTypeId(): number {
    const userRoleData = this.getSpecificData<UserRoleModel>("role");
    if (userRoleData && userRoleData.UserRoleTypeId) {
      return userRoleData.UserRoleTypeId;
    }
    return UserRoleType.Guest;
  }

  getUserRoleName(): string {
    const userRoleData = this.getSpecificData<UserRoleModel>("role");
    if (userRoleData && userRoleData.UserRoleName) {
      return userRoleData.UserRoleName;
    }
    return "";
  }

  getUserId(): number {
    const userData = this.getSpecificData<UserModel>("user");
    if (userData && userData.UserId) {
      return userData.UserId;
    }
    return 0;
  }

  getOrganizationUserId(): number {
    const orgUserId = this.getSpecificData<number>("orgUId");
    return orgUserId || 0;
  }

  getUserRoleId(): number {
    const userRoleData = this.getSpecificData<UserRoleModel>("role");
    if (userRoleData && userRoleData.UserRoleId) {
      return userRoleData.UserRoleId;
    }
    return 0;
  }

  getSignedInUserData(): UserModel {
    return this.getSpecificData<UserModel>("user");
  }

  getThemeData(): ThemeModel {
    return this.getSpecificData<ThemeModel>("theme");
  }

  getOrganizationData(): OrganizationModel {
    return this.getSpecificData<OrganizationModel>("organization");
  }

  getCurrentOrganizationURL(): string {
    const orgData = this.getOrganizationData();
    if (orgData && orgData.OrganizationURL) {
      return orgData.OrganizationURL;
    }
    return localStorage.getItem("base-href") || null;
  }

  getCurrentOrganizationId(): number {
    const orgData = this.getOrganizationData();
    if (orgData && orgData.OrganizationId) {
      return orgData.OrganizationId;
    }
    return null;
  }

  getUserMenuList(): NavigationMenuModel[] {
    const userMenuList = this.getSpecificData<NavigationMenuModel[]>("menu");
    if (userMenuList && userMenuList.length > 0) {
      return userMenuList;
    }
    return null;
  }

  getRouteForModule(moduleId: number): string {
    let menuList = this.getUserMenuList();
    if (menuList) {
      let menu = menuList.find((menuItem) => {
        return menuItem.ModuleId === moduleId;
      });
      return menu ? menu.RouteURL : "/";
    }
    return "/";
  }

  getModuleDetails(moduleId: number): NavigationMenuModel {
    let menuList = this.getUserMenuList();
    let menu = menuList.find((menuItem) => {
      return menuItem.ModuleId === moduleId;
    });
    return menu;
  }

  getConfiguredModuleName(
    moduleId: number,
    defaultName: string = "Module"
  ): string {
    let menuList = this.getUserMenuList();
    if (menuList) {
      let menu = menuList.find((menuItem) => {
        return menuItem.ModuleId === moduleId;
      });
      return menu ? menu.ModuleName : defaultName;
    }
    return defaultName;
  }

  getUserCustomerId(): string {
    const userData = this.getSignedInUserData();
    if (userData && userData.CustomerId) {
      return userData.CustomerId;
    }
  }

  getSpecificData<T>(dataIndex: string) {
    const cookieEncryptedUserData = localStorage.getItem(dataIndex);
    // this._cookieService.get(dataIndex);
    let dataToReturn: T;
    if (cookieEncryptedUserData) {
      const decrypted = CryptoJS.AES.decrypt(
        cookieEncryptedUserData,
        config.appId
      );
      const decryptedUtf8String = decrypted.toString(CryptoJS.enc.Utf8);
      if (decryptedUtf8String) {
        const parsedJSON = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
        dataToReturn = parsedJSON as T;
      }
    }
    return dataToReturn;
  }

  addDataToBrowserStorage(key: string, data: any) {
    const encryptedUserData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      config.appId
    );
    localStorage.setItem(key, encryptedUserData.toString());
    //this._cookieService.set(key, encryptedUserData.toString(), 365, '/');
  }

  addDataToBrowserStorageWithoutEncryption(key: string, data: any) {
    localStorage.setItem(key, data.toString());
  }

  manipulateDataToStoreInStorage(completeUserDetails: CompleteUserDetail) {
    this.addDataToBrowserStorage("role", completeUserDetails.UserRoleData);
    this.addDataToBrowserStorageWithoutEncryption(
      "base-href",
      completeUserDetails.OrganizationData.OrganizationURL
    );
    this.addDataToBrowserStorage(
      "organization",
      completeUserDetails.OrganizationData
    );
    this.addDataToBrowserStorage("user", completeUserDetails.UserData);
    this.addDataToBrowserStorage(
      "orgUId",
      completeUserDetails.OrganizationUserId
    );
    this.addDataToBrowserStorage("theme", completeUserDetails.ThemeData);
  }

  deleteSpecificCookie(key: string) {
    localStorage.removeItem(key);
    //this._cookieService.delete(key);
  }

  checkIfOperationPermitted(moduleId: number, operationIds: number[]): boolean {
    const moduleList: NavigationMenuModel[] =
      this.getSpecificData<NavigationMenuModel[]>("menu");
    if (!moduleList) {
      return false;
    }

    const moduleDetails = moduleList.find((obj) => {
      return obj.ModuleId === moduleId;
    });
    if (moduleDetails && moduleDetails.ModuleOperations) {
      const permittedModules = moduleDetails.ModuleOperations.filter(
        (modOp) => {
          return (
            operationIds.indexOf(modOp.ModuleOperationId) > -1 &&
            modOp.IsPermitted === true &&
            modOp.IsDeleted != true
          );
        }
      );
      if (permittedModules && permittedModules.length > 0) {
        return true;
      }
    }
    return false;
  }

  encryptURLParameter(urlParameter: string) {
    return this.encryptUsingAES256(urlParameter);
  }

  decryptURLParameter(encyptedURLParameter: string) {
    return this.decryptToStringUsingAES256(encyptedURLParameter);
  }

  // Methods for the encrypt and decrypt Using AES
  encryptUsingAES256(urlParameter: string) {
    const key = CryptoJS.enc.Utf8.parse(config.appKey);
    const iv = CryptoJS.enc.Utf8.parse(config.appIV);
    var encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(urlParameter),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return encrypted
      .toString()
      .replace(/\+/g, "p1L2u3S")
      .replace(/\//g, "s1L2a3S4h")
      .replace(/=/g, "e1Q2u3A4l");
  }

  decryptToStringUsingAES256(decString) {
    const key = CryptoJS.enc.Utf8.parse(config.appKey);
    const iv = CryptoJS.enc.Utf8.parse(config.appIV);
    let deciphertext: string = decString
      .replace(/p1L2u3S/g, "+")
      .replace(/s1L2a3S4h/g, "/")
      .replace(/e1Q2u3A4l/g, "=");
    const parsedDeciperText = CryptoJS.enc.Base64.parse(deciphertext);
    let bytes = CryptoJS.AES.decrypt(deciphertext, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);
    return bytes;
  }

  decryptUsingAES256(decString) {
    // let deciphertext: string = decString
    //   .replace(/p1L2u3S/g, '+')
    //   .replace(/s1L2a3S4h/g, '/')
    //   .replace(/e1Q2u3A4l/g, '=');
    const key = CryptoJS.enc.Utf8.parse(config.appKey);
    const iv = CryptoJS.enc.Utf8.parse(config.appIV);
    // var decrypted = CryptoJS.AES.decrypt(deciphertext, key, {
    //   keySize: 128 / 8,
    //   iv: iv,
    //   mode: CryptoJS.mode.CBC,
    //   padding: CryptoJS.pad.Pkcs7,
    // });
    let deciphertext: string = decString
      .replace(/p1L2u3S/g, "+")
      .replace(/s1L2a3S4h/g, "/")
      .replace(/e1Q2u3A4l/g, "=");
    const parsedDeciperText = CryptoJS.enc.Base64.parse(deciphertext);
    // let decData = parsedDeciperText.toString(CryptoJS.enc.Utf8);
    let bytes = CryptoJS.AES.decrypt(deciphertext, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);
    return JSON.parse(bytes);
  }

  signOutUser(currentUrl: string) {
    try {
      currentUrl = currentUrl
        ? currentUrl
        : this.router.url === "/sign-in"
        ? ""
        : this.router.url;
      //this._cookieService.deleteAll('/');
      const currentOrganizationURL = this.getCurrentOrganizationURL();
      localStorage.clear();
      this.router.resetConfig([
        {
          path: "",
          loadChildren: () =>
            import(
              "src/app/modules/sign-in-sign-up/sign-in-sign-up.module"
            ).then((m) => m.SignInSignUpModule),
        },
      ]);
      if (currentUrl.indexOf("/loading") > -1) {
        currentUrl = currentUrl.replace("/loading", "");
      }
      const existingRTInURL: string =
        this.activatedRoute.snapshot.queryParamMap.get("rt");
      if (existingRTInURL) {
        currentUrl = existingRTInURL;
      }
      const queryParameters: NavigationExtras =
        currentUrl && currentUrl.indexOf("404") === -1
          ? { queryParams: { rt: currentUrl } }
          : undefined;
      this.router.navigate(["/" + currentOrganizationURL], queryParameters);
    } catch (ex) {}
  }
}
