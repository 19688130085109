import { Component, Inject, ViewChild } from "@angular/core";
import { EventEmitterService } from "./shared/forms/services/event-emitter.service";
import { ToastService } from "ng-uikit-pro-standard";
import { ToastModel, ToastType } from "src/app/helpers/model/toast.model";
import { NotificationTemplates } from "./helpers/enums/notification.enum";
import { APP_BASE_HREF } from "@angular/common";
import { CommunicationsService } from "./modules/common/services/communications.service";
import { Observable, Observer, fromEvent, merge } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  showProgressBar: boolean = false;
  isAppOnline?: boolean = null;
  @ViewChild("onlineOfflineModal") onlineOfflineModal;

  constructor(
    @Inject(APP_BASE_HREF) public baseHref: string,
    private _eventEmiter: EventEmitterService,
    private toastrService: ToastService,
    private communicationService: CommunicationsService
  ) {
    this.registerProgressBarListener();
    this.registerToastListener();
    this.registerNotificationRequestListener();
    this.clearAllNotificationListener();
    this.assignNoInternetListener();
  }

  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  private registerProgressBarListener() {
    this._eventEmiter.progressBarManipulation.subscribe(
      (showOrHideProgressBar) => (this.showProgressBar = showOrHideProgressBar)
    );
  }

  private clearAllNotificationListener() {
    this._eventEmiter.clearAllNotificationListener.subscribe(() => {
      this.toastrService.clear();
    });
  }

  private registerToastListener() {
    this._eventEmiter.toastManipulation.subscribe((toastData: ToastModel) => {
      let toastInstance: any;
      if (toastData.RemovePreviousAlerts) {
        this.toastrService.clear();
      }
      const options = { opacity: 0.95, progressBar: true };
      switch (toastData.ToastType) {
        case ToastType.Success: {
          toastInstance = this.toastrService.success(
            toastData.Text || "Your request has been processed.",
            toastData.Title || "Great!",
            options
          );
          break;
        }
        case ToastType.Error: {
          toastInstance = this.toastrService.error(
            toastData.Text || "Something went wrong! Please try again.",
            toastData.Title || "Error!",
            options
          );
          break;
        }
        case ToastType.Info: {
          toastInstance = this.toastrService.info(
            toastData.Text || "Data updated!",
            toastData.Title,
            options
          );
          break;
        }
        case ToastType.Warning: {
          toastInstance = this.toastrService.warning(
            toastData.Text || "Something went wrong!",
            toastData.Title || "Oops!",
            options
          );
          break;
        }
        case ToastType.Permission: {
          this.toastrService.clear();
          toastData.Text = NotificationTemplates.Permission.replace(
            "{PermissionText}",
            toastData.Text
          );
          const notificationOptions = {
            opacity: 0.95,
            enableHtml: true,
            positionClass: "md-toast-bottom-left",
            closeButton: true,
            tapToDismiss: false,
            actionButton: "Click here",
            actionButtonClass: "accent btn-sm w-auto",
            timeOut: 500000,
            toastClass: "primary p-3",
            extendedTimeOut: 500000,
          };
          toastInstance = this.toastrService.info(
            toastData.Text,
            "",
            notificationOptions
          );
          break;
        }
        case ToastType.Notification: {
          const notificationOptions = {
            opacity: 0.95,
            enableHtml: true,
            positionClass: "md-toast-bottom-left",
            closeButton: true,
            tapToDismiss: true,
            toastClass: "primary p-3",
          };
          toastInstance = this.toastrService.info(
            toastData.Text,
            toastData.Title,
            notificationOptions
          );
          break;
        }
      }

      if (toastData.OnActionCallback) {
        toastInstance.onAction.subscribe(() => {
          toastData.OnActionCallback();
        });
      }

      if (toastData.OnHiddenCallback) {
        toastInstance.onHidden.subscribe(() => {
          toastData.OnHiddenCallback();
        });
      }

      if (toastData.OnShownCallback) {
        toastInstance.onShown.subscribe(() => {
          toastData.OnShownCallback();
        });
      }

      if (toastData.OnTapCallback) {
        toastInstance.onTap.subscribe(() => {
          toastData.OnTapCallback();
        });
      }
    });
  }

  private registerNotificationRequestListener() {
    this._eventEmiter.requestNotificationAccess.subscribe(() => {
      this.communicationService.getNotificationPermission();
    });
  }

  private assignNoInternetListener() {
    this.noInternetListener().subscribe((isOnline) => {
      if (this.onlineOfflineModal) {
        if (typeof isOnline === "boolean") {
          this.isAppOnline = isOnline;
        }

        if (this.isAppOnline == true) {
          setTimeout(() => {
            this.onlineOfflineModal.hide();
          }, 1000);
        } else {
          this.onlineOfflineModal.show();
        }
      }
    });
  }

  private noInternetListener() {
    return merge<any>(
      fromEvent(window, "offline").pipe(map(() => false)),
      fromEvent(window, "online").pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }
}
