export const APIURLs = {
  //-- Email templates
  GETEMAILCONTENT: '/Communication/GetEmailContent', //-- POST - Get Email template
  GETEMAILCONFIGURATION: '/Communication/GetEmailConfiguration', //-- GET
  SAVEEMAILCONFIGURATION: '/Communication/SaveEmailConfiguration', //-- POST

  //-- Communications
  SAVEUSERCOMMUNICATIONTOKEN: '/Communication/SaveUserCommunicationToken', //-- POST

  //-- Dashbaord
  GETUSERDASHBOARDDATA: '/Dashboard/GetUserDashboardData', //-- GET
  GETNUMBEROFUSERS: '/Dashboard/GetNumberOfUsers', //-- GET
  GETNUMBEROFSCHEDULEDTOURNAMENTS: '/Dashboard/GetNumberOfScheduledTournaments', //-- GET
  GETNUMBEROFREGISTRANTSFORGAME: '/Dashboard/GetNumberOfRegistrantsForGame', //-- GET

  //-- Layout / Navigation
  VERIFYBASEURL: '/Generic/VerifyBaseURL', //-- GET
  GETUSERORGANIZATIONSLIST: '/Organization/GetUserOrganizationsList', //-- GET
  SETDEFAULTORGANIZATION: '/Organization/SetDefaultOrganization', //-- POST
  GETCOMPLETEUSERDETAILS: '/User/GetCompleteUserDetails', //-- GET
  GETUSERROLEBASEDMODULES: '/Module/GetUserRoleBasedModules', //-- GET

  //-- Notification
  GETNEWANDUNREADNOTIFICATIONSCOUNT: '/Notification/GetNewAndUnreadNotificationsCount', //-- GET
  GETUSERNOTIFICATIONS: '/Notification/GetUserNotifications', //-- GET
  MARKALLNOTIFICATIONREAD: '/Notification/MarkAllNotificationRead', //-- GET

  //-- Country
  GETCOUNTRIESLIST: '/Generic/GetCountriesList', //-- GET

  //-- Organization
  GETLANDINGPAGECONTENT: '/Organization/GetLandingPageContent', //--GET
  GETORGANIZATIONSLIST: '/Organization/GetOrganizationsList', //-- POST
  GETORGANIZATIONIDFROMORGANIZATIONURL: '/Organization/GetOrganizationIdFromOrganizationURL', //-- GET
  GETORGANIZATIONDETAILS: '/Organization/GetOrganizationDetails', //-- GET
  CHECKORGANIZATIONURLAVAILABILITY: '/Organization/CheckOrganizationURLAvailability', //--GET
  CHECKIFORGANIZATIONEXISTS: '/Organization/CheckIfOrganizationExists', //-- GET
  CREATEORUPDATEORGANIZATION: '/Organization/CreateOrUpdateOrganization', //-- POST
  PROCESSUSERORGPRODUCTACCESS: '/Organization/ProcessUserOrgProductAccess', //-- GET

  //-- Department
  GETDEPARTMENTSLIST: '/Department/GetDepartmentsList', //-- GET
  GETDEPARTMENTDETAILS: '/Department/GetDepartmentDetails', //-- GET
  CREATEORUPDATEDEPARTMENT: '/Department/CreateOrUpdateDepartment', //-- POST
  CHECKDEPARTMENTURLAVAILABILITY: '/Department/CheckDepartmentURLAvailability', //-- GET

  //-- Theme
  GETORGANIZATIONTHEMESLIST: '/Theme/GetOrganizationThemesList', //-- GET
  GETTHEMEDETAILS: '/Theme/GetThemeDetails', //-- GET
  GETTHEMEDETAILSBYORGANIZATIONURL: '/Theme/GetThemeDetailsByOrganizationURL', //-- GET
  GETMINIATURETHEMEDETAILS: '/Theme/GetMiniatureThemeDetails', //-- GET
  CREATEORUPDATETHEME: '/Theme/CreateOrUpdateTheme', //-- POST
  CREATEORUPDATEMINIATURETHEME: '/Theme/CreateOrUpdateMiniatureTheme', //-- POST
  CHECKTHEMEURLAVAILABILITY: '/Theme/CheckThemeURLAvailability', //-- GET

  //-- Leaderboard
  GETLEADERBOARDDATA: '/Leaderboard/GetLeaderboardData', //-- POST

  //-- User role
  GETALLUSERROLES: '/UserRole/GetAllUserRoles', //-- GET
  GETUSERROLETYPES: '/UserRole/GetUserRoleTypes', //-- GET
  GETUSERROLEDETAILS: '/UserRole/GetUserRoleDetails', //-- GET
  CREATEORUPDATEUSERROLE: '/UserRole/CreateOrUpdateUserRole', //-- POST
  SAVEUSERROLEHIERARCHY: '/UserRole/SaveUserRoleHierarchy', //-- POST
  GETAPPROVERUSERROLES: '/UserRole/GetApproverUserRoles', // -- GET

  //-- User
  UPDATEEMAILVERIFIEDSTATUS: '/User/UpdateEmailVerifiedStatus', //--
  GETORGANIZATIONUSERS: '/Organization/GetOrganizationUsers', //-- POST
  GETUSERPROFILE: '/User/GetUserProfile', //-- GET
  GETALLUSERDETAILS: '/User/GetAllUserDetails', //-- GET
  UPDATEPROFILE: '/User/UpdateProfile', //-- POST
  UPDATEPROFILEPICANDBANNER: '/User/UpdateProfilePicAndBanner', //-- POST
  CHECKUSERNAMEAVAILABILITY: '/User/CheckUserNameAvailability', //-- GET
  GETGENDERLIST: '/Generic/GetGenderList', //-- GET
  CHECKUSERPROFILECOMPLETION: '/User/CheckUserProfileCompletion', //-- GET

  //-- Module
  GETORGANIZATIONMODULES: '/Module/GetOrganizationModules', //-- GET
  GETMODULECONTENT: '/Module/GetModuleContent', //-- GET
  SAVEMODULECONTENT: '/Module/SaveModuleContent', //-- POST
  CHKMODULECUSTOMROUTEURLAVAILABILITY: '/Module/ChkModuleCustomRouteURLAvailability', //-- GET
  GETMODULEPERMISSIONSFORALLUSERROLES: '/Module/GetModulePermissionsForAllUserRoles', //-- GET
  ADDORUPDATEMODULEDETAILS: '/Module/AddOrUpdateModuleDetails', //-- POST
  SAVEREQUESTAPPROVERINFO: '/Module/SaveRequestApproverInfo', //-- GET

  //-- Form
  GETFORMTYPES: '/Form/GetFormTypes', //-- GET
  GETORGANIZATIONFORMSLIST: '/Form/GetOrganizationFormsList', //-- GET
  CHECKFORMURLAVAILABILITY: '/Form/CheckFormURLAvailability', //-- GET
  GETFORMDETAILS: '/Form/GetFormDetails', //-- GET
  CREATEORUPDATEFORM: '/Form/CreateOrUpdateForm', //-- POST
  GETFORMINPUTFIELDS: '/Form/GetFormInputFields', //-- GET
  GETFORMINPUTTYPES: '/Form/GetFormInputTypes', //-- GET
  GETFORMCONTROLTYPES: '/Form/GetFormControlTypes', //-- GET
  GETAPIMETHODTYPES: '/Form/GetAPIMethodTypes', //-- GET
  SAVEDEFAULTFORM: '/Form/SaveDefaultForm', //-- POST
  SAVECUSTOMFORMINPUTS: '/Form/SaveCustomFormInputs', //-- POST
  GETCUSTOMFORMFORUSER: '/Form/GetCustomFormForUser', //-- GET
  SAVECUSTOMFORMWITHUSERDETAILS: '/Form/SaveCustomFormWithUserDetails', //-- POST

  //-- Invite
  GETINVITATIONSLIST: '/Invite/GetInvitationsList', //-- POST
  DELETEINVITATION: '/Invite/DeleteInvitation', //-- GET
  GETINVITATIONDETAILS: '/Invite/GetInvitationDetails', //-- GET
  INVITEUSERTOJOINORGANIZATION: '/Invite/InviteUserToJoinOrganization', //-- POST
  RESPONDTOORGANIZATIONINVITE: '/Invite/RespondToOrganizationInvite', //-- GET
  // GETINVITESTATUSLIST: '/Invite/GetInviteStatusList', //-- GET
  // SEARCHORGANIZATION?: '/Invite/SearchOrganization?', // -- GET
  // SEARCHUSER?: '/Invite/SearchUser', //-- GET
  // SENDJOINORGANIZATIONREQUEST: '/Invite/SendJoinOrganizationRequest', //-- GET
  // PROCESSORGANIZATIONJOINREQUEST: '/Invite/ProcessOrganizationJoinRequest', //-- GET

  //-- Request
  GETREQUESTSLIST: '/Request/GetRequestsList', //-- GET
  PROCESSREQUEST: '/Request/ProcessRequest', //-- GET
  CREATEREQUEST: '/Request/CreateRequest', //-- POST

  //-- Category
  GETCATEGORYLISTS: '/Category/GetCategoryLists', //-- GET
  GETCATEGORIES: '/Category/GetCategories', //-- GET
  GETCATEGORYDETAILS: '/Category/GetCategoryDetails', //-- GET
  GETCATEGORYLISTDETAILS: '/Category/GetCategoryListDetails', //-- GET
  CHECKCATEGORYLISTURLDUPLICACY: '/Category/CheckCategoryListURLDuplicacy', //-- GET
  CHECKCATEGORYURLDUPLICACY: '/Category/CheckCategoryURLDuplicacy', //-- GET
  CREATEORUPDATECATEGORY: '/Category/CreateOrUpdateCategory', //-- POST
  SAVECATEGORYLIST: '/Category/SaveCategoryList', //-- POST
  DELETECATEGORYLIST: '/Category/DeleteCategoryList', //-- GET
  GETTOURNAMENTCATEGORIES: '/Category/GetTournamentCategories', //-- GET
  GETTOURNAMENTCATEGORYLISTFORCATEGORY: '/Category/GetTournamentCategoryListForCategory', //-- GET
  GETCATEGORIESBYFLAG: '/Category/GetCategoriesByFlag', //--POST

  //-- Tournament
  FILTERTOURNAMENTS: '/Tournament/FilterTournaments', //-- POST - For User- User role type
  GETTOURNAMENTSLIST: '/Tournament/GetTournamentsList', //-- POST - For Admin/Super Admin user role types
  // GETTOURNAMENTDETAILS: '/Tournament/GetTournamentDetails', //-- GET - Returns tournament details - For Editing
  GETTOURNAMENTDETAILSANDOVERVIEW: '/Tournament/GetTournamentDetailsAndOverview', //-- GET
  GETTOURNAMENTPARTICIPANTS: '/Tournament/GetTournamentParticipants', //-- GET
  FILLCATEGORY2USERNAME: '/Tournament/FillCategory2UserName', //-- GET - Returns last updated platform Id of user
  CHECKCONDITIONSFORJOININGTOURNAMENT: '/Tournament/CheckConditionsForJoiningTournament', //-- GET
  JOINTOURNAMENT: '/Tournament/JoinTournament', //-- POST
  REMOVEPARTICIPANTFROMTOURNAMENT: '/Tournament/RemoveParticipantFromTournament', //-- GET
  UPDATETOURNAMENTPARTICIPATION: '/Tournament/UpdateTournamentParticipation', //-- POST - Used to leave tournament
  GETTOURNAMENTSCHEDULETYPES: '/Tournament/GetTournamentScheduleTypes', //-- GET - Returns list of all schedule types
  GETTOURNAMENTGAMESSCHEDULE: '/Tournament/GetTournamentGamesSchedule', //-- GET - Returns scheduled data for Single Elimination & Single Elimination - Home-Away tournaments
  GETTOURNAMENTGAMERESULT: '/Tournament/GetTournamentGameResult', //-- GET - Returns validate game result
  GETGAMESTOVERIFY: '/Tournament/GetGamesToVerify', //-- GET - Returns games to verify from Admin/Super admin
  GETSTANDINGSDATA: '/Tournament/GetStandingsData', //-- GET  - Returns standing data for single elimination
  SAVETOURNAMENTGAMEPARAMETERRESULT: '/Tournament/SaveTournamentGameParameterResult', //-- POST - Save validate game result - From tournament player
  SAVEVERIFIEDTOURNAMENTGAMEPARAMETERRESULT: '/Tournament/SaveVerifiedTournamentGameParameterResult', //-- POST -- Save approved game result - From Admin/Super admin to approve validation
  CREATEORUPDATETOURNAMENT: '/Tournament/CreateOrUpdateTournament', //-- POST - Save tournament details
  RESCHEDULETOURNAMENT: '/Tournament/ReScheduleTournament', //-- POST - Reschedule tournament
  ADDORUPDATETOURNAMENTCATEGORY: '/Tournament/AddOrUpdateTournamentCategory', //-- POST - Save category list and later add it to tournament
  GETTOURNAMENTOVERVIEWORRULE: '/Tournament/GetTournamentOverviewOrRule', //-- GET
  GETTOPSCORESFORLEADERBOARDTOURNAMENT: '/Tournament/GetTopScoresForLeaderboardTournament', //-- GET
  GETLEADERBOARDTOURNAMENTSCORES: '/Tournament/GetLeaderboardTournamentScores', //-- GET
  GETUSERGAMERESULTFORLEADERBOARDTOURNAMENT: '/Tournament/GetUserGameResultForLeaderboardTournament', //-- GET'
  GETTOURNAMENTGAMESCOREPARAMETERS: '/Tournament/GetTournamentGameScoreParameters', //-- GET
  GETLEADERBOARDTOURNAMENTSCORESTOVALIDATE: '/Tournament/GetLeaderboardTournamentScoresToValidate', //-- GET

  //-- Tournament admin
  CHECKIFTOURNAMENTISCOMPLETE: '/Tournament/CheckIfTournamentIsComplete', //-- GET
  SAVEPUBLISHDATETIME: '/Tournament/SavePublishDateTime', //-- GET
  CHECKTOURNAMENTURLAVAILABILITY: '/Tournament/CheckTournamentURLAvailability', // --  GET
  GETGAMESCOREPARAMETERS: '/Tournament/GetGameScoreParameters', //-- GET
  GETALLGAMESCOREPARAMETERSWITHUSEDINTOURNAMENTFLAG: '/Tournament/GetAllGameScoreParametersWithUsedInTournamentFlag', //-- GET
  SAVEGAMESCOREPARAMETER: '/Tournament/SaveGameScoreParameter', //-- POST
  GETTOURNAMENTGAMEPARAMETERS: '/Tournament/GetTournamentGameParameters', //-- GET
  SAVETOURNAMENTGAMEPARAMETER: '/Tournament/SaveTournamentGameParameter', //-- POST -- Add newly created parameters to DB
  SAVETOURNAMENTCONFIGURATION: '/Tournament/SaveTournamentConfiguration', //-- POST -- Save tournament ConfigurationJSON
  SCHEDULETOURNAMENT: '/Tournament/ScheduleTournament', //-- GET -- Used to schedule tournament
  CLEARSCHEDULE: '/Tournament/ClearSchedule', //-- GET -- Used to clear existing schedule
  UPDATETOURNAMENTROUNDTIME: '/Tournament/UpdateTournamentRoundTime', //-- POST - Updating tournament round times
  GETTOURNAMENTVISIBILITYDETAILS: '/Tournament/GetTournamentVisibilityDetails', //-- GET -- Used to get visibility details
  ADDORUPDATETOURNAMENTVISIBILITY: '/Tournament/AddOrUpdateTournamentVisibility', //-- POST -- Save visibility details
  DELETETOURNAMENT: '/Tournament/DeleteTournament', //-- GET -- Used to get DELETE tournament

  //-- Products
  GETTOURNAMENTPRODUCTS: '/Product/GetTournamentProducts', //-- GET -- Get list of tournament products
  SAVETOURNAMENTPRODUCT: '/Product/SaveTournamentProduct', //-- POST -- Save tournament products
  CHECKTOURNAMENTPRODUCTACCESS: '/Product/CheckTournamentProductAccess', //-- POST - Check if user already has bought tournament product
  GETORGANIZATIONPRODUCTS: '/Product/GetOrganizationProducts', //-- GET - Get list of organization products/memberships
  GETORGANIZATIONPRODUCTDETAILS: '/Product/GetOrganizationProductDetails', //-- GET - Get organization products/memberships details
  SAVEORGANIZATIONPRODUCT: '/Product/SaveOrganizationProduct', //-- POST Organization product
  PROCESSORDERNO: '/Product/ProcessOrderNo', //-- POST
  CONVERTPRODUCTCURRENCY: '/Tournament/ConvertProductCurrency', //-- POST
  GETMEMBERSHIPSUBSCRIBERS: '/Product/GetMembershipSubscribers', //-- GET

  //-- Message board
  FILTERSOCIALMESSAGES: '/MessageBoard/FilterSocialMessages', //-- POST
  GETMESSAGEDETAILS: '/MessageBoard/GetMessageDetails', //-- GET
  ADDORUPDATEMESSAGEBOARDCATEGORIES: '/MessageBoard/AddOrUpdateMessageBoardCategories', //-- POST
  POSTSOCIALMESSAGE: '/MessageBoard/PostSocialMessage', //-- POST
  POSTMESSAGELIKES: '/MessageBoard/PostMessageLikes', //-- POST
  GETMESSAGELIKEDUSERSLIST: '/MessageBoard/GetMessageLikedUsersList', //-- GET
  GETMESSAGECOMMENTS: '/MessageBoard/GetMessageComments', //-- GET
  REPORTMESSAGECOMMENT: '/MessageBoard/ReportMessageComment', //-- POST
  POSTMESSAGECOMMENTS: '/MessageBoard/PostMessageComments', // -- POST
  DELETESOCIALMESSAGE: '/MessageBoard/DeleteSocialMessage', //--GET
  REPORTMESSAGE: '/MessageBoard/ReportMessage', //--POST

  //--Campaigns
  GETALLCAMPAIGNS: '/ReferralCode/GetAllCampaigns', //-- POST
  GETCAMPAIGNDETAILS: '/ReferralCode/GetCampaignDetails', //-- GET
  CREATEOREDITCAMPAIGN: '/ReferralCode/CreateOrEditCampaign', //-- POST
  GETCAMPAIGNREFERRALCODES: '/ReferralCode/GetCampaignReferralCodes', //--POST
  CHECKREFERRALCODEAVAILABILITY: '/ReferralCode/CheckReferralCodeAvailability', //-- GET
  VALIDATECAMPAIGNURL: '/ReferralCode/ValidateCampaignURL', //-- GET
  DELETECAMPAIGN: '/ReferralCode/DeleteCampaign', //-- GET
  GETREFERRALCODEREWARDTYPES: '/ReferralCode/GetReferralCodeRewardTypes', //-- GET
  GETBULKREFERRALCODESFORPREVIEW: '/ReferralCode/GetBulkReferralCodesForPreview', //-- POST
  CREATEOREDITREFERRALCODES: '/ReferralCode/CreateOrUpdateReferralCodes', //-- POST
  VALIDATEANDAPPLYREFERRALCODE: '/ReferralCode/ValidateAndApplyReferralCode', //-- GET
  GETREFERRALCODEORGANIZATIONPRODUCT: '/ReferralCode/GetReferralCodeOrganizationProduct', //-- GET
  GETREFERRALCODEDETAILS: '/ReferralCode/GetReferralCodeDetails', //-- GET

  //-- Store
  SAVECUSTOMERORDERSANDTRANSACTIONS: '/Store/SaveCustomerOrdersAndTransactions', //-- GET
  VALIDATECARDBIN: '/Store/ValidateCardBIN', //-- POST
  GETALLOWEDPAYMENTMETHODS: '/Store/GetAllowedPaymentMethods', //-- GET

  //-- News
  GETNEWSTABS: '/News/GetNewsTabs', //-- POST
  GETNEWSITEMS: '/News/GetNewsItems', //-- POST
  DELETENEWSITEM: '/News/DeleteNewsItem', //-- GET
  GETNEWSTABDETAILS: '/News/GetNewsTabDetails', //-- GET
  CREATEORUPDATENEWSTAB: '/News/CreateOrUpdateNewsTab', //-- POST
  CREATEORUPDATENEWSSOURCE: '/News/CreateOrUpdateNewsSource', //-- POST
  GETRSSDATA: '/News/GetRSSData', //--POST
  REMOVENEWSSOURCEFROMTAB: '/News/RemoveNewsSourceFromTab', //-- POST
  CHECKIFNEWSSOURCEURLEXISTS: '/News/CheckIfNewsSourceURLExists', //-- POST
  DELETENEWSTAB: '/News/DeleteNewsTab', //-- GET

  //-- Stripe
  GETCUSTOMERPAYMENTMETHODS: '/Stripe/GetCustomerPaymentMethods', //-- GET
  CREATESETUPINTENT: '/Stripe/CreateSetUpIntent', //-- POST
  CREATESUBSCRIPTION: '/Stripe/CreateSubscription', //-- POST
  CREATECUSTOMERPORTAL: '/Stripe/CreateCustomerPortal', //-- GET
  GETCUSTOMERSUBSCRIPTIONS: '/Stripe/GetCustomerSubscriptions', //-- GET - Gets transaction history
  CREATEPAYMENTINTENT: '/Stripe/CreatePaymentIntent',

  //-- Bad words
  GETBADWORDLIST: '/Generic/GetBadWordList', //-- GET
};
