import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { config, firebaseConfig, rootAPIURL } from "src/config";
import { APIURLs } from "src/app/helpers/api-urls";
import { ToastType } from "src/app/helpers/model/toast.model";
import { ResponseHelper } from "src/app/helpers/model/response-helper";
import { UserCommunicationTokenModel } from "src/app/helpers/model/user-communication.model";
import { EventEmitterService } from "src/app/shared/forms/services/event-emitter.service";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { DeviceDetectorService } from "ngx-device-detector";
import { StorageHandler } from "src/app/helpers/storage-handler";
import { NotificationTemplates } from "src/app/helpers/enums/notification.enum";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MediaCategoryEnum } from "src/app/helpers/enums/message-board.enums";

@Injectable()
export class CommunicationsService {
  isUsePublicVapidKeyCalled: boolean = false;
  constructor(
    public _http: HttpClient,
    private fireMessaging: AngularFireMessaging,
    private deviceService: DeviceDetectorService,
    private storageHandler: StorageHandler,
    private eventEmitterService: EventEmitterService,
    private storage: AngularFireStorage
  ) {}

  getNotificationPermission() {
    switch (Notification.permission) {
      case "granted":
        this.getFirebaseToken();
        break;
      case "denied":
        this.handlingTokenAndUserData(null);
        break;
      case "default": {
        Notification.requestPermission().then((permission) => {
          permission === "granted"
            ? this.getFirebaseToken()
            : this.handlingTokenAndUserData(null);
        });
        break;
      }
    }
  }

  getFirebaseToken() {
    try {
      if (!this.isUsePublicVapidKeyCalled) {
        this.isUsePublicVapidKeyCalled = true;
        // this.fireMessaging.usePublicVapidKey(firebaseConfig.publicVapidKey);
        this.fireMessaging.getToken.subscribe((currentToken) => {
          this.receiveNewNotificationListener();
          this.handlingTokenAndUserData(currentToken);
        });
        // this.fireMessaging.onTokenRefresh((newToken) => {
        //   this.handlingTokenAndUserData(newToken);
        // });
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  receiveNewNotificationListener() {
    this.fireMessaging.messages.subscribe((payload: any) => {
      if (!payload) {
        return;
      }
      const notificationImageURL = payload.notification.image;
      let notificationText: string;
      if (notificationImageURL) {
        const imageRefData = this.storage.refFromURL(notificationImageURL);
        imageRefData.getMetadata().subscribe((metadata) => {
          if (metadata.contentType.indexOf(MediaCategoryEnum.Image) > -1) {
            notificationText = NotificationTemplates.PushNotificationWithImage;
            notificationText = notificationText.replace(
              "{PushImageURL}",
              notificationImageURL
            );
            this.showNotification(notificationText, payload);
          }
        });
        return;
      }
      notificationText = NotificationTemplates.PushNotificationWithoutImage;
      this.showNotification(notificationText, payload);
      this.eventEmitterService.refreshNotificationCount();
      return;
    });
  }

  showNotification(notificationText: string, payload: any) {
    if (payload.data && payload.data.ActionURL) {
      notificationText = notificationText.replace(
        "{NotificationRedirect}",
        payload.data.ActionURL || "#"
      );
    }
    notificationText = notificationText.replace(
      "{PushIconURL}",
      payload.notification.icon || config.defaultWhiteBrandIconURL
    );
    notificationText = notificationText.replace(
      "{PushTitle}",
      payload.notification.title
    );
    notificationText = notificationText.replace(
      "{PushBody}",
      payload.notification.body
    );
    this.eventEmitterService.showHideToast({
      ToastType: ToastType.Notification,
      Text: notificationText,
    });
  }

  handlingTokenAndUserData(currentToken: string) {
    const browserInformation = this.deviceService.getDeviceInfo();
    const loggedInUserId = this.storageHandler.getUserId();
    let communicationTokenData: UserCommunicationTokenModel = {
      DeviceBrowser: browserInformation.browser,
      DeviceId: null,
      DeviceName: browserInformation.userAgent,
      DeviceOS: browserInformation.os,
      DeviceType: browserInformation.device || "PC",
      DeviceVersion: browserInformation.os_version,
      IsActive: true,
      IsDeleted: false,
      Token: currentToken,
      UserId: loggedInUserId,
    };
    this.saveUserCommunicationToken(communicationTokenData).subscribe(() => {});
  }

  saveUserCommunicationToken(
    userCommunicationData: UserCommunicationTokenModel
  ): Observable<ResponseHelper> {
    return this._http
      .post<ResponseHelper>(
        rootAPIURL + APIURLs.SAVEUSERCOMMUNICATIONTOKEN,
        userCommunicationData
      )
      .pipe(
        catchError(
          this.handleError<ResponseHelper>("SaveUserCommunicationToken")
        )
      );
  }

  public handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      return of(result as T);
    };
  }
}
