import { Injectable, EventEmitter } from "@angular/core";
import { ToastModel } from "src/app/helpers/model/toast.model";

@Injectable()
export class EventEmitterService {
  progressBarManipulation = new EventEmitter();
  toastManipulation = new EventEmitter();
  organizationDetailsManipulation = new EventEmitter();
  menuDetailsManipulation = new EventEmitter();
  organizationThemeManipulation = new EventEmitter();
  themeDetailsManipulation = new EventEmitter();
  userDetailsManipulation = new EventEmitter();
  requestNotificationAccess = new EventEmitter();
  clearAllNotificationListener = new EventEmitter();
  refreshNotificationCountListener = new EventEmitter();
  refreshOrganizationUsersListListener = new EventEmitter();

  constructor() {}

  showHideProgressBar(status: boolean) {
    this.progressBarManipulation.emit(status);
  }

  showHideToast(toastData: ToastModel) {
    this.toastManipulation.emit(toastData);
  }

  organizationDetailsUpdated() {
    this.organizationDetailsManipulation.emit();
  }

  menuDetailsUpdated() {
    this.menuDetailsManipulation.emit();
  }

  themeDetailsUpdated() {
    this.themeDetailsManipulation.emit();
  }

  userDetailsUpdated() {
    this.userDetailsManipulation.emit();
  }

  requestForNotificationPermission() {
    this.requestNotificationAccess.emit();
  }

  clearAllNotifications() {
    this.clearAllNotificationListener.emit();
  }

  refreshNotificationCount() {
    this.refreshNotificationCountListener.emit();
  }

  refreshOrganizationUsersList() {
    this.refreshOrganizationUsersListListener.emit();
  }
}
